import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  Homepage,
  Aboutus,
  Service,
  Contactus,
  BlogDetail,
  Createblog,
  NotFound,
  Bootcamp,
  BootcampDetails,
  Hiring,
  HiringDetail,
  Resources,Blogs,
  Experts
} from "../Pages";
import HackathonPage from "../Pages/Hackathon";



export default function AppRouter() {
  return (
    <BrowserRouter basename="/" forceRefresh={true}>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/services" element={<Service />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="/bootcamp" element={<Bootcamp />} />
        <Route path="/hackathon" element={<HackathonPage />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/bootcamp/details" element={<BootcampDetails />} />
        <Route path="/hiring" element={<Hiring />} />
        <Route path="/hiring/:slug" element={<HiringDetail />} />
        <Route path="/blogs/:slug" element={<BlogDetail />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/experts" element={<Experts />} />

        <Route path="/createblog" element={<Createblog />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
