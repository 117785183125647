import HeroImage from "../Images/hero-image.png";
import Microsoft from "../Images/microsoft.jpg";
import Keller from "../Images/keller.jpg";
import laudio from "../Images/laudio.png";
import testimonial_awards from "../Images/testimonials-awards.png";
import testimonial_right from "../Images/testimonials-right-quote.png";
import testimonial_left from "../Images/testimonials-left-quote.png";
import Arbind from "../Images/arbind.jpg";
import Deepak from "../Images/deepak.png";
import Sumit from "../Images/sumit-datascience.jpeg";
import Hero_service from "../Images/hero-services.svg";
import service_delivery from "../Images/services-service-delivery.svg";
import service_procurement from "../Images/services-procurement.svg";
import Hero_mobile from "../Images/hero-mobile-work.svg";
import banner from "../Images/banner.png";
import contact from "../Images/contact.png";
import firefly from "../Images/firefly.png";
import girl from "../Images/girl.png";
import girl1 from "../Images/girl1.png";
import contacts from "../Images/contacts1.png";
import health from "../Images/health.jfif";
import education from "../Images/education.jfif";
import finance from "../Images/finance.jfif";
import social from "../Images/social.jfif";
import ecommerce from "../Images/ecommerce.jfif";
import about_test from "../Images/about-test.png";
import people_probs from "../Images/people-probs.jpeg";
import quit from "../Images/quit.jpeg";
import target from "../Images/target.png";
import communication from "../Images/communication.png";
import collaboration from "../Images/collaboration.webp";
import adaptability from "../Images/adaptability.png";
import value from "../Images/value.png";
import hr from "../Images/hr.png";
import service from "../Images/service.png";
import bishesh from "../Images/bishesh.png";
import datas_science from "../Images/datas-science.png";
import dummy2 from "../Images/dummy2.png";
import amazon from "../Images/amazonwebservices.png";
import django from "../Images/django.png";
import docker from "../Images/docker.png";
import go from "../Images/go.png";
import javascript from "../Images/javascript.png";
import kaggle from "../Images/kaggle.png";
import kubernetes from "../Images/kubernetes.png";
import mysql from "../Images/mysql.png";
import nextjs from "../Images/nextjs.png";
import numpy from "../Images/numpy.png";
import pandas from "../Images/pandas.png";

import postgresql from "../Images/postgresql.png";
import python from "../Images/python.png";
import react from "../Images/react.png";
import redis from "../Images/redis.png";
import terraform from "../Images/terraform.png";
import vscode from "../Images/vscode.png";
import sweta from "../Images/sweta1.jpg";
import machine from "../Images/machine.png";
import lock from "../Images/lock.png";
import home from "../Images/home.JPG";
import home1 from "../Images/home.png";
import aasha from "../Images/aasha.png";
import danfe from "../Images/danfe.png";
import vc from "../Images/vc.png";
import program from "../Images/program.png";
import sql from "../Images/sql.png";
import transantiago from "../Images/transantiago.JPG";
import cisco from "../Images/cisco.png";
import okta from "../Images/okta.png";
import rsa from "../Images/rsa.svg";
import graphql from "../Images/graphql.png";
import jenkins from "../Images/jenkins.png";
import unity from "../Images/unity.png";
import booking from "../Images/booking.png";
import web from "../Images/web.jfif";
import api from "../Images/api.jfif";
import db from "../Images/db.jfif";
import aws from "../Images/aws.jfif";
import terraforms from "../Images/terraform.jfif";
import ai from "../Images/ai.png";
import deploy_ai from "../Images/deploy-ai.png";
import generative_ai from "../Images/generative-ai.jfif";
import custom_model from "../Images/custom-model.jfif";
import incident from "../Images/incident.JPG";
import network from "../Images/network.JPG";
import compliance from "../Images/compliance.png";
import bidit from "../Images/Bidit.jpeg";
import nikesh from "../Images/Nikesh.jpeg";
import pratiksha from "../Images/Pratiksha.jpeg";
import rahul from "../Images/rahul_mahaseth.jpeg";
import bishesh1 from "../Images/bishesh.jpg";
import static_web from "../Images/static-website.png";
import vurnelabilities from "../Images/vurnelabilities.JPG";
import adobe from "../Images/adobe.png";
import amazon1 from "../Images/amazon.png";
import cloud from "../Images/cloud.png";
import gitlab from "../Images/gitlab.png";
import facebook from "../Images/facebook.png";
import dummy3 from "../Images/dummy3.webp";
import bidit_meta from "../Images/Bidit_Meta.jpeg";
import anish_google from "../Images/Anish_Google.jpeg";
import diwas_airbnb from "../Images/Diwas_Airbnb.jpeg";
import apoorva_netflix from "../Images/Apoorva_Netflix.jpeg";
import shauharda_microsoft from "../Images/Sauharda_Microsoft.jpeg";
import suman_oracle from "../Images/Suman_Oracle.jpeg";
import google from "../Images/google-logo.png";
import airbnb from "../Images/airbnb_logo.png";
import netflix from "../Images/netflix_logo.jpg";
import microsoft from "../Images/microsoft_logo.png";
import oracle from "../Images/oracle_logo.png";
import hire from "../Images/hiring_search.png";
import freefor from "../Images/freeforgirls.png";
import frameboot from "../Images/Frameboot.png";
import banner4 from "../Images/banner4.png";
import suweb from "../Images/suweb.jpeg";
import yuvraj from "../Images/yuvraj.jpg";
import roshan from "../Images/roshan.jpg";
import suruchi from "../Images/suruchi.webp";
import jyoti from "../Images/jyoti.png";
import labbi from "../Images/labbi.png";
import ojaswi from "../Images/ojaswi.jpeg";
import marlyn from "../Images/marlyn.jpg";
import ian from "../Images/ian.jpg";
import puru from "../Images/puru.jpeg";
import Neelgai_resource from "../Images/neelgai-resource.png";
import Neelgai_vs_Deloitte from "../Images/neelgaivsdeloitte.png";
import blog_test from "../Images/blog-test.png";
import team  from "../Images/team.png";


const Images = {
  team,
  yuvraj,
  blog_test,
  ojaswi,
  ian,
  puru,
  marlyn,
  Neelgai_resource,
  Neelgai_vs_Deloitte,
  jyoti,
  labbi,
  suruchi,
  roshan,
  banner4,
  adobe,
  frameboot,
  freefor,
  google,
  airbnb,
  netflix,
  microsoft,
  oracle,
  amazon1,
  cloud,
  gitlab,
  facebook,
  danfe,
  dummy3,
  static_web,
  bidit,
  bishesh1,
  nikesh,
  pratiksha,
  rahul,
  home1,
  vurnelabilities,
  incident,
  network,
  compliance,
  ai,
  deploy_ai,
  generative_ai,
  custom_model,
  aws,
  terraforms,
  api,
  db,
  web,
  booking,
  graphql,
  jenkins,
  unity,
  cisco,
  okta,
  rsa,
  transantiago,
  sql,
  program,
  aasha,
  vc,
  machine,
  home,
  lock,
  terraform,
  vscode,
  mysql,
  nextjs,
  pandas,
  numpy,
  postgresql,
  python,
  react,
  redis,
  HeroImage,
  Microsoft,
  Keller,
  laudio,
  testimonial_awards,
  testimonial_left,
  testimonial_right,
  Arbind,
  Deepak,
  Sumit,
  Hero_service,
  service_delivery,
  service_procurement,
  Hero_mobile,
  banner,
  contact,
  firefly,
  girl,
  girl1,
  contacts,
  health,
  education,
  finance,
  ecommerce,
  social,
  about_test,
  people_probs,
  quit,
  target,
  communication,
  collaboration,
  adaptability,
  hr,
  value,
  service,
  bishesh,
  datas_science,
  dummy2,
  amazon,
  django,
  docker,
  go,
  javascript,
  kaggle,
  kubernetes,
  sweta,
  bidit_meta,
  apoorva_netflix,
  suman_oracle,
  shauharda_microsoft,
  anish_google,
  diwas_airbnb,
  hire,
  suweb,
};

export default Images;
