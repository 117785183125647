import React from 'react';
import { useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import './Image.css';


export default function ImageComponent(props) {
    const {images, defaultImageUrl, color, className,alt} = props;
    const [loading, setLoading] = useState(true);
    const handleLoad = () => {
        setLoading(false);
    };

  return (
    <div >
            <picture className={loading ? "img-loading" : ""} >
                {images.map((image, index) => (
                    <source key={index} srcSet={image.url} />
                ))}
                <img onLoad={handleLoad} src={defaultImageUrl} alt={alt} className={className} />  
            </picture>
            <PulseLoader
            loading={loading}
            color={color || "#212529"}
            size={20}
            margin={10}
            cssOverride={{margin: "6rem 0rem"}}
            aria-label="Loading Spinner"
            data-testid="loader"
            className='d-flex justify-content-center'
            />
        </div>

  );
}
