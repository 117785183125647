import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import { Power3 } from "gsap";
import "./Createblog.css";

export default function Createblog() {
  const textRef = useRef(null);
  const timeoutRef = useRef(null);
  const texts = ["Payroll", "Onboarding", "Taxes"];
  const animationDuration = 0.3;
  const delayBeforeNextAnimation = 3000;
  let currentIndex = 0; // Start with the first text

  useEffect(() => {
    const animateText = () => {
      // Calculate the index for the next text
      currentIndex = (currentIndex + 1) % texts.length;

      // Tween animation using GSAP
      gsap.fromTo(
        textRef.current,
        { opacity: 0, y: -50 },
        {
          duration: animationDuration,
          opacity: 1,
          y: 0,
          ease: Power3.easeIn,
          onComplete: () => {
            // Set a timeout to fade out the text after a delay
            timeoutRef.current = setTimeout(() => {
              if (textRef.current) {
                gsap.to(textRef.current, {
                  duration: animationDuration,
                  opacity: 0,
                  ease: Power3.easeOut,
                  onComplete: () => {
                    if (textRef.current) {
                      // Update the text content once fade out is complete
                      textRef.current.textContent = texts[currentIndex];
                      // Call animateText recursively for the next text
                      animateText();
                    }
                  },
                });
              }
            }, delayBeforeNextAnimation);
          },
        }
      );
    };

    // Start the animation loop
    animateText();

    // Cleanup function
    return () => {
      // Clear any pending timeouts when the component unmounts
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <div
        style={{
          fontSize: "24px",
          lineHeight: "150px",
        }}
      >
        <span
          ref={textRef}
          style={{
            color: "#fff",
            backgroundColor: "black", // Background color
            padding: "10px", // Optional padding
            borderRadius: "5px", // Optional border radius
          }}
        >
          {texts[currentIndex]}
        </span>
      </div>
    </div>
  );
}
