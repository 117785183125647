import { Footer, Header } from "../../Common";
import CalenderContextProvider from "../../Context/calendar.context";
import Hackathon from "./Hackathon";


const HackathonPage = () => {
    return (
        <>
            
            <CalenderContextProvider>
                <Header isHackathon />
                <Hackathon />
                <Footer isHackathon />
            </CalenderContextProvider>
        </>
    )
}

export default HackathonPage