import React from 'react'
import './style.scss'


export const RegisterForm = () => {
    return (
        <div className="Register_a" style={{display: 'flex', justifyContent: 'center', 'gap': '1rem', flexWrap: 'wrap'}}> 
        </div>
    )
}

