import { Col, Row } from 'react-bootstrap';
import { SOCIALS } from '../../../Constant/hackathon';
import { Btn } from '../MyInfo';
import './sponsors.scss';

function SponsorsHead({title}) {
  return <h1 className="shead">{title}</h1>;
}

function Sponsor(props) {
  return (
    <div className="Sponsor">
      <img src={props.srcx} alt="Limbo hacks"></img>
    </div>
  );
}

export const SponsorGroup = (props, index) => {
  return (
    <Row key={index} style={{justifyContent: 'center'}}>
      {props.map((s, i) => (
        <Col key={i} className="" sm={12} lg={4} md={6}>
          {' '}
          <Sponsor srcx={s.src} />{' '}
        </Col>
      ))}
    </Row>
  );
};

function SponsorUS() {
  return (
    <div className="joinT sponsorUS">
      <h3>Interested in Sponsoring </h3>
      <a href={SOCIALS.email}>
        <Btn className="sponsor_btn" type="Sponsor us" overlay="Send a mail" />
      </a>{' '}
    </div>
  );
}

export {SponsorsHead, Sponsor, SponsorUS};
