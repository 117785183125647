import { Col, Row } from "react-bootstrap";
import { Btn } from "../MyInfo";
import './style.css'
export const JoinTeam = ({ placeholder, formLink, content }) => {
    return (
        <div className="joinT">
            <h3>{content} </h3>
            <a href={formLink}>
                <Btn type={placeholder} overlay="Fill the form" link={formLink} />
            </a>
        </div>
    );
};
export const Member = ({ info }) => {
    const { github, role, name, img, linkedin } = info;
    return (
        <div className="member">
            <img src={img} alt="Limbo hack"></img>
            <div className="members-link">
                <h3>{name}</h3>
                <p>{role}</p>
                <a href={github}>
                    <i className="fab fa-2x fa-github"></i>
                </a>
                <a href={linkedin}>
                    <i className="fab fa-2x fa-linkedin"></i>
                </a>
            </div>
        </div>
    );
};

export const TeamMembers = (props, index) => {
    return (
        <Row key={index} className="members">
            {props.map((s, i) => (
                <Col key={i} className="" sm={12} lg={4} md={4}>
                    <Member info={s} />
                </Col>
            ))}
        </Row>
    );
};