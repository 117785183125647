import { ReactTyped } from "react-typed";
import { SOCIALS, TOP_SECTION } from "../../Constant/hackathon"
import './myinfo.css';
export const Btn = props => {
    return (
        <button className={props.class}>
            <i className={props.ico}></i>
            {props.type}
            <div className="overlay">
                <div className="overlay-text">{props.overlay}</div>
            </div>
        </button>
    );
};

export const About = () => {
    return (
        <div className="AboutMe">
            <h2>{TOP_SECTION.TITLE}</h2>
            <div className="typing-div">
                <h1>
                    <ReactTyped strings={TOP_SECTION.Typed_effect} typeSpeed={80} loop />
                </h1>
            </div>
        </div>
    )
}

export const MyInfo = () => {
    return (
        <div className="Myinfo text-white p-2">
            <About />
            <p>{TOP_SECTION.SHORT_DESCRIPTION}</p>
            <div className="join_dis">
                <a rel="noreferrer" target="_blank" href={SOCIALS.discord} style={{display: 'flex', justifyContent: 'center', textDecoration: 'none'}}>
                    <Btn
                        ico="fab fa-2x fa-discord"
                        class="join_disco"
                        type="Join our discord"
                        overlay="Click the link"
                    />
                </a>
            </div>
            <div className="buttom-group" style={{ display: "flex", flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', width: '100%' }}>

                {/* <a href={TOP_SECTION.FORM_LINK_INDIVIDUAL} target="_blank" rel="noreferrer"> */}
                    {" "}
                    <Btn
                        class="register"
                        type="Registeration Closed"
                        overlay="Registeration Closed"
                    />

                {/* </a> */}
                {/* <a href={TOP_SECTION.FORM_LINK_TEAM} target="_blank" rel="noreferrer"> */}
                    {" "}
                    <Btn
                        class="register"
                        type="Registeration Closed"
                        overlay="Registeration Closed"
                    />

                {/* </a> */}

            </div>
        </div>
    )
}


