import React from 'react';
import "./Experts.css";
import { Header, Footer } from "../../Common"; 
import { Helmet } from "react-helmet"; 
import { Link } from "react-router-dom";
import Images from "../../Constant/Images";
import Accordion from "react-bootstrap/Accordion";

export default function Experts() {


  const faq = [
    {
      id: 1,
      question: "How do FAANG Engineers work with you?",
      answer:
        "1. Knowledge of any one programming language (Python, Java, C++) or other languages <div> 2. Have completed Bachelors or equivalent</div> 3. Interested in learning Computer Science or Data Science ",
    },
    {
      id: 2,
      question: "Do you provide services not listed on this page?",
      answer:
        "This is a bootcamp led by Neelgai. Neelgai has a office in Janakpur. It will be held on 2nd floor (previously N.K Shopping Mall) at Janak Chowk, Janakpur -03. There is a banner there currently.",
    },

    
  ];
 
  const expert = [
    {
      id: 1,
      image: Images.dummy2,
      name: "Anish Adhikari",
      position: "Software Engineer at Google",
      desc: "Previously at Uber and now as a Software Engineer at Google, Anish will share insights on how to crack the Big Tech Coding Interviews.",
    },
    {
      id: 2,
      image: Images.dummy2,
      name: "Pratiksha Sharma",
      position: "Vice President, Goldman Sachs",
      desc: "A software engineer at Goldman Sachs, Pratiksha specializes in innovative tech solutions within dynamic financial environments, driven by precision and forward-thinking strategies.",
    },
    
  ];
  const speakers = [
    {
      id: 1,
      name: "Anish Adhikari",
      position: "Software Engineer, Google",
      image: Images.dummy2,
      logo: Images.google,
      desc: "​Mr.Anish is a skilled software engineer currently at Google. He specializes in Google Cloud services. Previously at Uber, he is proficient at developing high-performance systems.",
      topic: "Best Practices in Python",
    },
    {
      id: 2,
      name: "Apoorva Lal",
      position: "Data Scientist, Netflix",
      image: Images.dummy2,
      logo: Images.netflix,
      desc: "​Mr.Apoorva, a Data Scientist at Netflix leverages analytics to drive content recommendations and enhance viewer experience through data-driven insights and machine learning algorithms.",
      topic: "Experimentation and Causal Machine Learning",
    },
    {
      id: 3,
      name: "Bidit Sharma",
      position: "Production Engineer, Meta",
      image: Images.dummy2,
      logo: Images.facebook,
      desc: "​Mr.Bidit Sharma is a Senior Production Engineer at Meta specializing in ensuring seamless operations and scalability of infrastructure to support innovative technological advancements and user experiences.",
      topic: "Fires and Fixes in Hyperscale",
    },
    {
      id: 4,
      name: "Diwas Timilsina",
      position: "Senior Software Engineer, Airbnb",
      image: Images.dummy2,
      logo: Images.airbnb,
      desc: "​Mr. Diwas Timilsina, a Senior Software Engineer at Airbnb, specializes in distributed systems, optimizing platform performance, and creating seamless user experiences with over 7+ years of industry experience.",
      topic: "Introduction to Distributed Systems",
    },
    {
      id: 5,
      name: "Shauharda Khadka",
      position: "Applied Data Scientist, Microsoft",
      image: Images.dummy2,
      logo: Images.microsoft,
      desc: "​Mr. Shauharda Khadka, a Senior Data & Applied Scientist at Microsoft, leverages machine learning and advanced analytics to drive innovation and data-driven decisions across various high-impact projects.",
      topic: "Reinforcement Learning",
    },
    {
      id: 6,
      name: "Suman Nepal",
      position: "Senior Software Engineer, Oracle",
      image: Images.dummy2,
      logo: Images.oracle,
      desc: "​Mr. Suman Nepal is a senior software engineer at Oracle working on developing data visualization software on the web, primarily with HTML, CSS, and JavaScript.",
      topic: "JavaScript Essentials: Exploring Overlooked Concepts",
    },
  ];
  return (
    <div className="experts">

<Helmet>
        <title>Experts Bootcamp | Training in Software Development, AI, Mobile Apps & Cybersecurity</title>
        <meta
          name="description"
          content="Learn about Neelgai, a software development company dedicated to providing fast and cost-effective tech services. Discover our mission, values, and the challenges we solve for businesses."
        />
        <meta
          name="keywords"
          content="Neelgai, software development, about us, tech services, mission, values"
        />
        <link rel="canonical" href="https://www.neelgai.com/experts" />
        
        <meta property="og:title" content="Bootcamp - Neelgai" />
        <meta property="og:description" content="Discover Neelgai's mission and values in software development." />
        <meta property="og:url" content="https://www.neelgai.com" />
        <meta property="og:image" content="https://www.neelgai.com/static/media/neelgai-resource.33b90f763a3d91cf9f35.png" /> {/* Replace with an actual image URL */}
        <meta property="twitter:card" content="https://www.neelgai.com/static/media/neelgai-resource.33b90f763a3d91cf9f35.png" />
        <meta property="twitter:title" content="Experts - Neelgai" />
        <meta property="twitter:description" content="Learn about Neelgai and our software development services." />
        <meta property="twitter:image" content="https://www.neelgai.com/static/media/neelgai-resource.33b90f763a3d91cf9f35.png" /> {/* Replace with an actual image URL */}
      </Helmet>
      <Header />

      <section className="bg-boot text-light p-5 p-lg-0 pt-lg-5 text-center">
        <div className="container">
          
          <div className="d-md-flex align-items-center justify-content-between">
            <div className="text-start pb-5">
              <h1 className="boot-head text-start">
                Meet Our Experts
              </h1>
              <p className="lead py-4 text-start">
              We come from diverse backgrounds, including FAANG companies, the Big Four, Ivy League, and regions spanning the US, Europe, Asia, and Australia. They contribute as managers, advisors, consultants, or architects.
              </p>
              <Link to='/contact'><button className="btn btn-start btn-lg text-start">
                Let's Meet
              </button></Link>
            </div>
            <img src={Images.team} className="img-fluid boot-free" />
          </div>
        </div>
      </section>
      <div className="svg-boot"></div>


      <section className="p-sm-5">
        <div className="container pt-5">
          <div className="row experts g-4">
            <div className="col-md">
              <div className="card ">
                <div className="card-body p-md-5">
                  <div className="row  bg-experts  ">
                    <div className="col-md-6 text-start">
                      <div>
                        <span className="feature py-1 px-2">
                          Featured Speakers
                        </span>
                      </div>
                      <h4 className="meet-experts pt-4 pt-md-3">
                        Meet the Panel Speakers
                      </h4>
                      <div className="learn-expert">
                        Learn from industry-leading experts in our upcoming zoom
                        session
                      </div>
                    </div>
                    <div className="col-md-6 text-start ">
                      {expert.map((elem, index) => (
                        <div className="row mt-5 mt-md-3" key={index}>
                          <div className="col-md-3  col-xl-2">
                            <img
                              src={elem.image}
                              className="img-fluid img-experts"
                            />
                          </div>
                          <div className="col-md-9 col-xl-10">
                            <div className="expert-name">{elem.name}</div>
                            <div className="expert-desc">{elem.position}</div>
                            <div className="expert-desc">{elem.desc}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="py-4  text-start text-md-center">
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="p-sm-5">
        <div className="container ">
          <div className="row experts g-4">
            <div className="col-md">
              <div className="card ">
                <div className="card-body px-0 px-md-5">
                  <div className="row  bg-experts  ">
                    <h4 className="meet-experts pt-4 pt-md-3">
                      Featured Big Tech Sessions
                    </h4>
                    <div className="learn-expert">
                      Meet the speakers from Big Tech Companies who will be
                      sharing their insights at our special session via zoom
                    </div>
                  </div>

                  <div className="row mt-3  pb-5">
                    {speakers.map((elem, index) => (
                      <div
                        key={index}
                        className=" col-lg-4  px-0 mt-3  mt-md-5 "
                      >
                        <div className=" box-speakers m-3 pt-4 px-4 ">
                          <div className="row ">
                            <div className="col-3 px-0">
                              <img
                                src={elem.image}
                                className="img-fluid img-speakers"
                              />
                            </div>
                            <div className="col-7 text-start ">
                              <div className="speaker-name">{elem.name}</div>
                              <div className="speaker-pos">{elem.position}</div>
                            </div>
                            
                          </div>

                          <div className=" text-start pt-2 speaker-pos">
                            {elem.desc}
                          </div>
                          <div className="row speaker-align">
                            <div className="  text-center speaker-name ">
                              Topic: {elem.topic}
                            </div>
                            <div className="text-center   pb-4 pt-2">
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div className="svg-bottom"></div>
      <section className="py-5 ps-2 ps-md-4 pt-5 faq-box">
        <div className="container">
          <h3 className="text-start text-faq pb-4">FAQs</h3>

          <div className="row justify-content-center px-0 mx-0 pb-5 mb-5">
            <div className="col-md-8">
              <Accordion defaultActiveKey="0">
                {faq.map((elem, index) => (
                  <Accordion.Item eventKey={elem.id.toString()} key={index}>
                    <Accordion.Header>
                      <span className="me-3 item-num">0{elem.id}</span>
                      <span className="text-question ms-1">
                        {elem.question}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body
                      className="text-start ms-md-4"
                      dangerouslySetInnerHTML={{ __html: elem.answer }}
                    ></Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      
    </div>
  )
}
