import React, { useState } from "react";
import { Header, Footer,ImageComponent } from "../../Common";
import Images from "../../Constant/Images";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { GrAttachment } from "react-icons/gr";
import ReCAPTCHA from "react-google-recaptcha";
import Form from "react-bootstrap/Form";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Helmet } from "react-helmet"; 

import "./Contactus.css";

export default function Contactus() {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    useremail: "",
    phone: "",
    message: "",
    service: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.firstname) formErrors.firstname = "First name is required";
    if (!formData.lastname) formErrors.lastname = "Last name is required";
    if (!formData.useremail) formErrors.useremail = "Email is required";
    if (!formData.phone) formErrors.phone = "Phone number is required";
    if (!formData.message) formErrors.message = "Message is required";
    if (!formData.service) formErrors.service = "Service selection is required";

    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const userID = "Xxqs16cfH1fyhjbKZ";
    const serviceID = "service_6tw5yoq";
    const templateID = "template_u5u3wml";

    emailjs
      .send(serviceID, templateID, formData, userID)
      .then((response) => {
        toast.success(
          "Thank you for reaching out to Neelgai. We will get back to as soon as we can."
        );
        setFormData({
          firstname: "",
          lastname: "",
          useremail: "",
          phone: "",
          message: "",
          service: "",
        });
      })
      .catch((error) => {
        toast.error("Failed to send email.");
      });
  };

  return (
    <div>
       <Helmet>
        <title>Contact Neelgai | Software Development, AI, Mobile Apps & Cybersecurity</title>
        <meta
          name="description"
          content="Learn about Neelgai, a software development company dedicated to providing fast and cost-effective tech services. Discover our mission, values, and the challenges we solve for businesses."
        />
        <meta
          name="keywords"
          content="Neelgai, software development, about us, tech services, mission, values"
        />
        <link rel="canonical" href="https://www.neelgai.com/contact" />
        {/* Add Open Graph and Twitter Card tags as needed */}
        <meta property="og:title" content="Contact Us - Neelgai" />
        <meta property="og:description" content="Discover Neelgai's mission and values in software development." />
        <meta property="og:url" content="https://www.neelgai.com/contact" />
        <meta property="og:image" content="https://www.neelgai.com/static/media/neelgai-resource.33b90f763a3d91cf9f35.png" /> {/* Replace with an actual image URL */}
        <meta property="twitter:card" content="https://www.neelgai.com/static/media/neelgai-resource.33b90f763a3d91cf9f35.png" />
        <meta property="twitter:title" content="Contact Us - Neelgai" />
        <meta property="twitter:description" content="Learn about Neelgai and our software development services." />
        <meta property="twitter:image" content="https://www.neelgai.com/static/media/neelgai-resource.33b90f763a3d91cf9f35.png" /> {/* Replace with an actual image URL */}
      </Helmet>
      <Header />
      <h1 style={{ position: 'absolute', opacity: 0 }}>
        Contact us
      </h1>
      <section className="contactus mt-5 pt-5">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <h1 className="text-start content-title py-4">
                Get started with Neelgai
              </h1>
              <div className="form_box">
                <form onSubmit={handleSubmit} id="contact-form">
                  <div className="form_inputs_box">
                    <div className="form_user_info row">
                      <div className="input_container col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Firstname"
                            name="firstname"
                            value={formData.firstname}
                            onChange={handleChange}
                            className={errors.firstname ? "error" : ""}
                          />
                        </div>
                        {errors.firstname && (
                          <div className="error-message">
                            {errors.firstname}
                          </div>
                        )}
                      </div>
                      <div className="input_container col-md-6 pt-4 pt-md-0">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Lastname"
                            name="lastname"
                            value={formData.lastname}
                            onChange={handleChange}
                            className={errors.lastname ? "error" : ""}
                          />
                        </div>
                        {errors.lastname && (
                          <div className="error-message">{errors.lastname}</div>
                        )}
                      </div>
                    </div>
                    <div className="form_user_info row">
                      <div className="input_container col-md-6 mt-4 pt-2">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Email"
                            name="useremail"
                            value={formData.useremail}
                            onChange={handleChange}
                            className={errors.useremail ? "error" : ""}
                          />
                        </div>
                        {errors.useremail && (
                          <div className="error-message">
                            {errors.useremail}
                          </div>
                        )}
                      </div>
                      <div className="input_container col-md-6 my-4 py-2">
                        <div className="form-group">
                          <input
                            type="tel"
                            placeholder="Phone number"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className={errors.phone ? "error" : ""}
                          />
                        </div>
                        {errors.phone && (
                          <div className="error-message">{errors.phone}</div>
                        )}
                      </div>
                    </div>
                    <div className="input_container">
                      <select
                        id="selectOption"
                        name="service"
                        value={formData.service}
                        onChange={handleChange}
                        className={errors.service ? "error" : ""}
                      >
                        <option value="" disabled>
                          Select a service *
                        </option>
                        <option value="UI/UX Design">UI/UX Design</option>
                        <option value="Android/IOS Development">
                          Android/IOS Development
                        </option>
                        <option value="Web App Development">
                          {" "}
                          Web App Development
                        </option>
                        <option value="Infrastructure Management">
                          Infrastructure Management
                        </option>
                        <option value="AI/ML">AI/ML</option>
                        <option value="Cyber Security">Cyber Security</option>
                      </select>
                      {errors.service && (
                        <div className="error-message">{errors.service}</div>
                      )}
                    </div>
                    <div className="input_container my-4 py-2">
                      <textarea
                        id="textarea"
                        placeholder="Additional Information"
                        rows={8}
                        cols={50}
                        className={`w-100 pt-3 plmessage ${
                          errors.message ? "error" : ""
                        }`}
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                      />
                      {errors.message && (
                        <div className="error-message">{errors.message}</div>
                      )}
                    </div>
                  </div>
                  <button
                    className="btn btn-info my-3 my-md-5"
                    type="submit"
                    value="Send"
                  >
                    Send
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-6 img-over">
              <div className="text-contact">
                <div>
                  <h3 className="text-start pt-md-5">Email:</h3>
                  <p className="text-start contact-email">
                    <a href="mailto:support@neelgai.com">support@neelgai.com</a>
                  </p>
                </div>
                <div className="d-xl-flex">
                  <div className="col-8 col-sm-6 col-xl-6">
                    <h3 className="text-start pt-3">US offices:</h3>
                    <p className="text-start contact-phone">
                      Arlington, VA (HQ) <br />
                      Austin, TX
                    </p>
                  </div>
                  <div className="col-8 col-sm-6 col-xl-9">
                    <h3 className="text-start pt-3">Nepal offices:</h3>
                    <p className="text-start contact-phone">
                      Budhanilkantha, Kathmandu <br />
                      Janakchowk, Janakpur
                    </p>
                  </div>
                </div>
              </div>
             
               <ImageComponent images={[{ url: `${Images.contacts}`}]}
                                         defaultImageUrl={`${Images.contacts}`}
                                         className="img-fluid h-100 "
                                         alt="Neelgai-contact"
                                         />
            </div>
          </div>
        </div>
      </section>
      <div className="contact-cont"></div>
      <Footer />
      <ToastContainer className="custom-toast" />
    </div>
  );
}
