import { useContext } from 'react';
import * as H from './style';
import { calenderContext } from '../../../Context/calendar.context';
import { Flexbox } from './calender.layout';

export const CalenderView = () => {
  const contextTesting = useContext(calenderContext);
  const {state, dispatch} = contextTesting;
  return (
    <div className="card-body">
      {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, j) => (
        <Flexbox key={j} alignCenter justifyCenter className="card-body-header">
          <p style={{color: "#9E9E9E"}}>{day}</p>
        </Flexbox>
      ))}
      {state.dates.map((day, j) => (
        <Flexbox
          alignCenter
          justifyCenter
          className={
            day.dumpDay
              ? 'card-body-inner'
              : 'card-body-inner card-body-inner__active'
          }
          onClick={() => {
            if (day.event) {
              dispatch({type: 'UPDATE_CALENDER_VIEW', data: 2});
              dispatch({
                type: 'SHOW_EVENTS_FOR_SELECTED_DATE',
                data: state.dates[j].events
              });
            } else {
              alert('No events');
            }
          }}
          key={j}
        >
          {day.event ? (
            <p className="card-body-inner__active__event">{day.day}</p>
          ) : (
            <p>{day.day}</p>
          )}
        </Flexbox>
      ))}
    </div>
  );
};

export const EventsView = () => {
  const contextTesting = useContext(calenderContext);
  const {state} = contextTesting;
  return (
    <H.EventContainer>
      {state.selectedData.map((event, j) => (
        <H.Event
          key={j}
          onClick={() => {
            window.open(event.link, '_blank');
          }}
        >
          <div className="event-subcard">
            <h3>{event.title}</h3>
            <p>{event.timings}</p>
          </div>
          {/* <button>Add to calender</button> */}
        </H.Event>
      ))}
    </H.EventContainer>
  );
};

