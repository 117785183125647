import { useContext } from "react";
import * as H from './style';
import styled from 'styled-components';
import { calenderContext } from "../../../Context/calendar.context";
import { CALENDER_VIEW, months } from "../../../Constant/hackathon";

const desktop = '@media only screen and (min-width: 992px)';

export const Flexbox = styled.div`
  display: flex;
  flex-direction: column;
  flex-direction: ${({ flexRow }) => flexRow && 'row'};
  flex-direction: ${({ flexCol }) => flexCol && 'column'};
  justify-content: space-between;
  justify-content: ${({ justifyBtw }) => justifyBtw && 'space-between'};
  justify-content: ${({ justifyAround }) => justifyAround && 'space-around'};
  justify-content: ${({ justifyFlexStart }) => justifyFlexStart && 'flex-start'};
  justify-content: ${({ justifyFlexEnd }) => justifyFlexEnd && 'flex-end'};
  justify-content: ${({ justifyStart }) => justifyStart && 'start'};
  justify-content: ${({ justifyEnd }) => justifyEnd && 'end'};
  justify-content: ${({ justifyAround }) => justifyAround && 'space-around'};
  justify-content: ${({ justifyCenter }) => justifyCenter && 'center'};
  align-items: center;
  align-items: ${({ alignStart }) => alignStart && 'flex-start'};
  align-items: ${({ alignCenter }) => alignCenter && 'center'};
  align-items: ${({ alignEnd }) => alignEnd && 'flex-end'};
  ${desktop} {
    flex-direction: ${({ flexRowLg }) => flexRowLg && 'row'};
    flex-direction: ${({ flexColLg }) => flexColLg && 'column'};
  }
`;

const CalenderLayout = ({
    children,
    handleActionProcced,
    handleActionBack
}) => {
    const contextTesting = useContext(calenderContext);
    const { state, dispatch } = contextTesting;
    return (
        <H.Container>
            <Flexbox className="card-top" flexRow>
                <h3 style={{ fontSize: 20 }} color="white">
                    Events schedule {months[state.month - 1]} {state.year}
                </h3>
                <Flexbox flexRow className="card-top-inner">
                    <Flexbox
                        justifyCenter
                        alignCenter
                        className="card-top-inner-item"
                        onClick={() => dispatch(handleActionBack)}
                    >
                        P
                    </Flexbox>
                    <Flexbox
                        justifyCenter
                        alignCenter
                        className={`card-top-inner-item ${state.index !== CALENDER_VIEW && 'card-top-inner-item__disabled'
                            }`}
                        onClick={() => dispatch(handleActionProcced)}
                    >
                        N
                    </Flexbox>
                </Flexbox>
            </Flexbox>
            {children}
        </H.Container>
    );
};

export default CalenderLayout;
