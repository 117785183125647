import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { blogs } from "../../Constant/blog";
import { Header, Footer } from "../../Common";
import { fetchPosts } from "../../Constant/sanityClient";
import { Helmet } from "react-helmet"; 
import { PortableText } from '@portabletext/react';

export default function BlogDetail() {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  
  

  useEffect(() => {
    fetchPosts()
      .then((data) => {
        
        const selectedBlog = data.find((p) => p.slug.current === slug);
        // console.log(selectedBlog)
        if (!selectedBlog) {
          console.error("No blog found with the provided slug:", slug);
        }
        setBlog(selectedBlog);
      })
      .catch((error) => console.error("Error fetching blog:", error))
      .finally(() => setLoading(false)); 
  }, [slug]);

 

  const customComponents = {
    marks: {
     
      link: ({ children, value }) => (
        <a href={value.href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
    },
  };
  
  
  return (
    <div>
      {blog && (
        <Helmet>
          <title>{`${blog.title} - Hiring at Neelgai`}</title>
          <meta
            name="description"
            content={`Join our blogs`}
          />
          <meta property="og:title" content={`${blog.title} - Blogs at Neelgai`} />
          <meta
            property="og:description"
            content={`Blog regarding ${blog.title}`}
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={`https://www.neelgai.com/blogs/${slug}`} />
          <meta property="og:image" content="https://www.neelgai.com/static/media/neelgai-resource.33b90f763a3d91cf9f35.png" />
          <meta name="twitter:title" content={`${blog.title} - Blogs at Neelgai`} />
          <meta
            name="twitter:description"
            content={`Blog regarding ${blog.title}`}
          />
          <meta name="twitter:card" content="https://www.neelgai.com/static/media/neelgai-resource.33b90f763a3d91cf9f35.png" />
          <meta name="twitter:image" content="https://www.neelgai.com/static/media/neelgai-resource.33b90f763a3d91cf9f35.png" />
        </Helmet>
      )}
      <Header />
      {loading ? ( 
       <div class="spinner-border text-secondary" role="status">
       <span class="sr-only">Loading...</span>
     </div>
      ) : (
      <div className="container mt-5 pt-5 col-8">
        <h1 className="blog-title text-start">{blog?.title}</h1>
        <div className="blog-date text-start my-4">{new Date(blog?.date).toLocaleDateString()}</div>
        <div className="row">
                {blog?.postedBy?.map((elem, index) => (
                  <div key={index} className="col-xs-10 col-md-4">
                    <div className="d-flex">
                      <div>
                        <img
                          src={elem.image?.asset?.url}
                          alt="image-blog"
                          className="img-fluid img-experts me-3 mt-2 "
                        />
                      </div>
                      <div className="my-auto">{elem.name}</div>
                    </div>
                  </div>
                ))}
              </div>
        
        {blog?.blog_image&& (<img src={blog?.blog_image?.asset?.url} alt="image-blog" className="img-fluid img-detail-blog my-3" />)}
        
        <div
          className="text-start skill-more py-4"
          
        ><PortableText value={blog?.details} components={customComponents} /></div>
      </div>
       )}
      <Footer />
    </div>
  );
}

