import React, { useState, useEffect } from "react";
import { Header, Footer } from "../../Common";
import { blogs } from "../../Constant/blog";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Blogs.css";
import { fetchPosts } from '../../Constant/sanityClient';
import { PortableText } from '@portabletext/react';
import { Helmet } from "react-helmet"; 
import Images from "../../Constant/Images";

export default function Blogs() {
 
  const truncateText = (text = "", length) => {
    if (!text || text.length <= length) return text;
    return text.substring(0, length) + "...";
  };

  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    fetchPosts().then((data) => setBlogs(data));

  }, []);
  if (!blogs.length) return <div class="spinner-border text-secondary" role="status">
  <span class="sr-only">Loading...</span>
</div>;


  
  return (
    <div>
      <Helmet>
        <title>Join Neelgai | Careers in Software Development, AI, Mobile Apps & Cybersecurity</title>
        <meta
          name="description"
          content="Learn about Neelgai, a software development company dedicated to providing fast and cost-effective tech services. Discover our mission, values, and the challenges we solve for businesses."
        />
        <meta
          name="keywords"
          content="Neelgai, software development, about us, tech services, mission, values"
        />
        <link rel="canonical" href="https://www.neelgai.com/blogs" />
       
        <meta property="og:title" content="Blogs - Neelgai" />
        <meta property="og:description" content="Discover Neelgai's mission and values in software development." />
        <meta property="og:url" content="https://www.neelgai.com/blogs" />
        <meta property="og:image" content="https://www.neelgai.com/static/media/neelgai-resource.33b90f763a3d91cf9f35.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content="Blogs - Neelgai" />
        <meta property="twitter:description" content="Learn about Neelgai and our software development services." />
        <meta property="twitter:image" content="https://www.neelgai.com/static/media/neelgai-resource.33b90f763a3d91cf9f35.png" /> 
      </Helmet>
      <Header />
      <div className="container mb-5">
        <div className="row mt-lg-5   text-start mx-0 blog">
       
          {blogs?.map((blog, index) => (
            <div key={index} className="col-lg-6 px-0 mt-5 pt-5 blog-box">
               {/* {console.log(blog)} */}
           
              <Link to={`/blogs/${blog.slug.current}`} title="Blog">
                <h1 className="blog-title me-lg-3">{blog.title}</h1>
              </Link>

              {/* <img
                    src={blog.image.url}
                    alt=""
                    className="img-fluid"
                  /> */}
              <div className="blog-date"> {new Date(blog.date).toLocaleDateString()}</div>
              <div className="col-sm-10 text-start pt-2">
                
                {blog.details &&
                  blog.details.slice(0, 1).map((block) => (
                    <p key={block._key}>
                      {truncateText(
                        block.children?.map((child) => child.text).join(" ") || "",
                        250 
                      )}
                    </p>
                  ))}
              </div>
              <div className="row mx-0 my-3">
                {blog?.tags?.map((elem, index) => (
                  <div
                    key={index}
                    
                    className="col-5 col-md-3 tag-text me-2 text-center mt-2"
                  >
                    <div className="tag-title">{elem}</div>
                  </div>
                ))}
              </div>
              <div className="row">
                {blog?.postedBy?.map((elem, index) => (
                  <div key={index} className="col-xs-10 col-md-6">
                    <div className="d-flex">
                      <div>
                        <img
                          src={elem.image.asset.url}
                          alt="image-blog"
                          className="img-fluid img-experts me-3 mt-2"
                        />
                      </div>
                      <div className="my-auto">{elem.name}</div>
                    </div>
                  </div>
                ))}




              </div>
              {/* <div className="m-2">
                {blog.image && (
                  <img
                    src={blog.image.asset.url}
                    alt="image-blog"
                    className="img-fluid"
                  />
                )}
                
              </div> */}
            </div>
          ))}
        </div>
      </div>
      <Footer />
      
    </div>


    
  );
}
