import { Col, Container, Row } from 'react-bootstrap'
import pattern from './assets/pattern4.png'
import { MyInfo } from './MyInfo'
import './index.css'
import { FOOTER, frequentlyAskedQuestions, hostingPartners, JudgesInfo, mediaPartners, mentorsInfo, Prizeinfo, schedule, sponsorLogos, supportedBy, TeamInfo, TOP_SECTION } from '../../Constant/hackathon'
import { useEffect, useState } from 'react'
import Media from './Social'
import { Logo, LogoSectionAbout } from './LogoSection'
import { UseMedia } from '../../hooks/useMedia'
import Birds from './Animation/Birds'
import { Accordion } from './Accordian'
import { PrizeGroup, PrizeHeading } from './Prizes'
import { SponsorGroup, SponsorsHead, SponsorUS } from './Sponsors'
import { JoinTeam, TeamMembers } from './JoinTeam'
import { RegisterForm } from './RegisterForm'
import { MyCalender } from './Calendar/Calender'
import EnrollmentStats from './EnrollmentStats/EnrollmentStats'



const FrequentlyAsked = (props, index) => {
  return (
    <Row key={index} className="sf">
      {props.map((s, i) => {
        return (
          <Col key={i} sm={12} lg={6} md={6}>
            <Accordion panels={s} />
          </Col>
        )
      })}
    </Row>
  );
};

const Hackathon = () => {

  const [media, setMedia] = useState();
  UseMedia('min-width', 1000, setMedia)

  useEffect(() => {
    const bodyElem = document.querySelector('body');
    bodyElem.classList.add('bg-primary-hackathon')
    return (() => {
      bodyElem.classList.remove('bg-primary-hackathon')
    })
  }, [])

  return (
    <>
      <section className='bg-primary-hackathon Whole_div hackathon' style={{ minHeight: '100vh', backgroundImage: `url(${pattern})` }}>
        <div className='color_sectiom bg-primary-hackathon' id="home">
          <Container fluid>
            <Row className='Row info'>
              <Col className='info-div' sm={12} lg={7} md={7}>
                <MyInfo />
              </Col>
              <Col className='d-image' sm={12} lg={5} md={5}>
                <MyCalender data={schedule} />
              </Col>
              <Row className="mediaInfo">
                <Col className="" sm={12} lg={12} md={12}>
                  <Media />
                </Col>
              </Row>
            </Row>
          </Container>
        </div>
        <Container fluid>
          <EnrollmentStats />
          <Row className='logoSection'>
            <Col className="info-div" sm={12} lg={8} md={8}>
              <LogoSectionAbout />
            </Col>
            <Col className="info-div" sm={12} lg={4} md={4}>
              <Logo />
            </Col>
          </Row>
          {/* ********Frequently asked Questions here ***** */}
          <div className="Myfaqs" id="faq">
            {frequentlyAskedQuestions.map(FrequentlyAsked)}
            {/* ********Frequently asked Questions ending here ***** */}
          </div>
          {/* ********Prizes here ***** */}
          <Row className="prizesection" id="prizes">
            <PrizeHeading type="Prize section" />
            {Prizeinfo.map(PrizeGroup)}
          </Row>
          {/* ********Prizes ending here ***** */}

          <Row className="sponsorSection" id="sponsors">
            <SponsorsHead title="Our Sponsors" />
            <SponsorUS />
            {sponsorLogos.map(SponsorGroup)}
          </Row>
          {/* ********Sponsors ending here ***** */}
          {/* ********Hosting PartNers  here ***** */}

          <Row className="sponsorSection" id="sponsors">
            <SponsorsHead title="Our Hosting Partners" />
            {hostingPartners.map(SponsorGroup)}
          </Row>
          {/* ********Hosting Partners ending here ***** */}
          {/* ********Supporters PartNers  here ***** */}

          <Row className="sponsorSection" id="sponsors">
            <SponsorsHead title="Supported by" />
            {supportedBy.map(SponsorGroup)}
          </Row>
          {/* ********Hosting Partners ending here ***** */}
          {/* ********Media PartNers  here ***** */}

          <Row className="sponsorSection" id="sponsors">
            <SponsorsHead title="Our Media Partners" />
            {mediaPartners.map(SponsorGroup)}
          </Row>
          {/* ********Media Partners ending here ***** */}

          {media && <Birds top="105vh" left="0vh" type="" />}

          {/* ********Team here ***** */}
          <h1 id="team">Organizing Committee</h1>
          {FOOTER.JOIN_TEAM.required && (
            <JoinTeam
              placeholder="Join our team"
              formLink={FOOTER.JOIN_TEAM}
              content="Interested in joining our team"
            />
          )}
          {TeamInfo.map(TeamMembers)}
          {/* ********Team ending here ***** */}
          {/* ********Judges here ***** */}

          <h1 id="team">Judges</h1>
          {FOOTER.JOIN_TEAM.required && (
            <JoinTeam
              placeholder="Join our team"
              formLink={TOP_SECTION.JUDGES_FORM_LINK}
              content="Interested in being judge"
            />
          )}
          {JudgesInfo.map(TeamMembers)}
          {/* ********Mentors Start here ***** */}
          <h1 id="team">Mentors</h1>
          {mentorsInfo.map(TeamMembers)}

        </Container>
        {/* Footer Register Form Link starts here*/}
        <RegisterForm />

      </section>
    </>
  )
}

export default Hackathon