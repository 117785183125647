import { createClient } from '@sanity/client';

export const sanityClient = createClient({
    projectId: "7kcntuvo",
    dataset: "production",
    apiVersion: "2022-03-07",
    useCdn: false,
    
  });

  const query = `*[_type == "blog"]  | order(date desc){ _id, title,details,tags,slug,date,
  blog_image {
    asset -> {
      url
    }
  },
   image {
    asset -> {
      url
    }
  }
    ,postedBy[]->{
            name,
            image {
    asset -> {
      url
    }
  }
          } 
        
   }`;
  
  export async function fetchPosts() {
  
    return sanityClient.fetch(query);
  }