import React, { useContext, useEffect } from 'react'
import { calenderContext } from '../../../Context/calendar.context'
import { calenderMainLogic } from './calender.function';
import { CALENDER_VIEW } from '../../../Constant/hackathon';
import { AnimatePresence, motion } from 'framer-motion';
import { CalenderView, EventsView } from './calender.component';
import CalenderLayout from './calender.layout';


export const MyCalender = ({ data }) => {
    const contextTesting = useContext(calenderContext);
    const { state, dispatch } = contextTesting;

    useEffect(() => {
        calenderMainLogic(state, dispatch, data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.month]);
    return (
        <CalenderLayout
            handleActionProcced={
                state.index === CALENDER_VIEW ? { type: 'MONTH_FORWARD' } : { type: 'DISABLED' }
            }
            handleActionBack={
                state.index === CALENDER_VIEW ? { type: 'MONTH_BACKWARD' } : { type: 'UPDATE_CALENDER_VIEW', data: 1 }
            }
        >
            <AnimatePresence mode="wait">
                <motion.div
                    key={state.index ?? 'empty'}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -1, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    {state.index === CALENDER_VIEW ? <CalenderView /> : <EventsView />}
                </motion.div>
            </AnimatePresence>

        </CalenderLayout>
    )
}

