// components/EnrollmentStats.js
import React from 'react';
import './style.css';
import { enrollmentStats } from '../../../Constant/hackathon';
import CountUp from 'react-countup';
import useInView from '../../../hooks/useInView';


const EnrollmentStats = () => {
    const [ref, isInView] = useInView();

    return (
        <div className="container my-4">
            <div className="row" ref={ref}>
                {
                    enrollmentStats.map((item, index) => (
                        <div key={index} className="col-12 col-md-4 mb-3 mb-md-0">
                            <div className="card shadow-sm rounded-lg text-start p-4">
                                <h5 className="card-title">{item.title}</h5>
                                {
                                    isInView && (
                                        <CountUp start={0} end={item.count} duration={2} className="card-text" suffix='+' />
                                    )
                                }
                                {/* <h3 className="card-text">{item.count}</h3> */}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default EnrollmentStats;
