import { CALENDER_VIEW, calenderStartingDate } from "../Constant/hackathon";

export const initialState = {
    index: CALENDER_VIEW,
    selectedData: [],
    selectedEvent: {},
    dates: [],
    ...calenderStartingDate
};

const calenderReducer = (state, payload) => {
    switch (payload.type) {
        case 'UPDATE_CALENDER_VIEW':
            return { ...state, index: payload?.data };
        case 'SHOW_EVENTS_FOR_SELECTED_DATE':
            return { ...state, selectedData: payload?.data };
        case 'UPDATE_DATES':
            return { ...state, dates: payload?.data };
        case 'MONTH_FORWARD': {
            if (state.month === 12) return { ...state, month: 1, year: state.year + 1 };
            return { ...state, month: state.month + 1 };
        }

        case 'MONTH_BACKWARD': {
            if (state.month === 1) return { ...state, month: 12, year: state.year - 1 };
            return { ...state, month: state.month - 1 };
        }
        default:
            return state;
    }
}

export default calenderReducer