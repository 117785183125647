import boy from './assets/boy.png'
import hackathonLogo from './assets/hackathonLogo.png'
import c4NLogo from './assets/sponsorsLogos/c4n_logo.png';
import leapFrogLogo from './assets/sponsorsLogos/leapfrog.png';
import avniMedia from './assets/sponsorsLogos/avni-media.png';
import logoPassport from './assets/sponsorsLogos/logo-passport.png';
import Protozoa from './assets/sponsorsLogos/protozoa-logo.svg';
import maaMadheshLogo from './assets/sponsorsLogos/maamadhesh.png';
// Importing all Team members images  from Assets

import deepakShah from './assets/judges/deepak-shah.jpeg';
import surajSah from './assets/judges/surajkumarsah.jpg';
import nandaniShah from './assets/teami/nandani-shah.jpg';
import satNarayanSah from './assets/teami/sat-narayan-sah.jpeg';
import suruchiShah from './assets/teami/suruchi-shah.jpeg';
import mikeySharma from './assets/teami/mikey-sharma.png';
import pankajShah from './assets/teami/pankaj-shah.jpg';
import milanSah from './assets/teami/milan-sah.png';
import parulSah from './assets/teami/parul-sha.jpg';


//Importing all Mentors Images from Assets
import yuvraj from './assets/mentors/yuvraj.jpg'
import roshanJha from './assets/mentors/roshan.jpg'
import sujitSir from './assets/mentors/sumit-datascience.jpeg'
import bibekSir from './assets/mentors/bibek-thapa.jpeg'
import nawajSarif from './assets/mentors/nawaj-sarif.png'


export const CALENDER_VIEW = 1;

export const calenderStartingDate = {
    month: 11,
    year: 2024
};

export const NAVIGATION_OFFSET = 66;

export const TOP_SECTION = {
    TITLE: 'Janakpur Hackathon',
    Typed_effect: ['48 hours of creation', 'Win awesome prizes', 'Solve real-world problems'],
    SHORT_DESCRIPTION:
        'Join us from 23rd to 24th November 2024 at Neelgai Janakpur Office with innovative minds for 48 hours of creativity, collaboration, and problem-solving.',
    IMG_SRC: boy, // Ensure the 'boy' image is properly defined/imported
    DISCORD_LINK: 'https://discord.gg/wNfjc8Vs ', // Add a link to your Discord channel if available
    FORM_LINK_INDIVIDUAL:
        'https://docs.google.com/forms/d/e/1FAIpQLSerDxlCiCfzpJwuUuLNcSJDFCldpaliV-7TXQ5CN7V3NtKHMw/viewform?usp=sf_link', // This looks good, you can retain the same form link
    FORM_LINK_TEAM:
        'https://forms.gle/bip48UuhcNCTWKxR7' // Registration form link looks correct
};


export const SOCIALS = {
    instagram: 'https://www.instagram.com/neelgaitech/',
    discord: 'https://discord.gg/y44d43wE',
    linkedin: 'https://www.linkedin.com/company/neelgai',
    facebook: 'https://www.facebook.com/profile.php?id=61558400588347',
    email: 'mailto:hackathon@neelgai.com',
    mail: 'hackathon@neelgai.com'
};

export const enrollmentStats = [
    {
        title: 'Total Enrollments',
        count: 100
    },{
        title: 'Teams Applied',
        count: 10
    },{
        title: 'Cities Represented',
        count: 10
    }
]

export const schedule = [
    {
        day: '23-11-2024',
        events: [
            {
                title: 'Hackathon Begins',
                timings: '9 AM',
                link: '' // Add relevant link if needed
            },
            {
                title: 'Introduction',
                timings: '9 AM',
                link: ''
            },
            {
                title: 'Ice Breaker',
                timings: '10 AM',
                link: ''
            },
            {
                title: 'Lunch',
                timings: '11 AM',
                link: ''
            },
            {
                title: 'Coding',
                timings: '12 PM',
                link: ''
            },
            {
                title: 'Nasta',
                timings: '3 PM',
                link: ''
            },
            {
                title: 'Coding',
                timings: '4 PM',
                link: ''
            },
            {
                title: 'Night Dinner',
                timings: '8 PM',
                link: ''
            },
            {
                title: 'Program',
                timings: 'After 9 PM',
                link: ''
            }
        ]
    },
    {
        day: '24-11-2024',
        events: [
            {
                title: 'Tea Time',
                timings: '7 AM',
                link: ''
            },
            {
                title: 'Lunch',
                timings: '10 AM',
                link: ''
            },
            {
                title: 'Breakfast',
                timings: '2 PM',
                link: ''
            },
            {
                title: 'Project Submission',
                timings: '4 PM',
                link: ''
            },
            {
                title: 'Prize Distribution',
                timings: 'After 4 PM',
                link: ''
            },
            {
                title: 'Hackathon Ends',
                timings: '6 PM', // Adjust if needed
                link: ''
            }
        ]
    }
];


export const weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
];

export const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec'
];

export const MIDDLE_SECTION = {
    TITLE: 'What is Janakpur Hackathon?',
    LONG_DESCRIPTION:
        'Janakpur Hackathon is a 48-hour hackathon taking place from November 23rd to 24th, 2024, inviting hackers, coders, designers, tech enthusiasts, and developers from various backgrounds to solve real-world problems. Participants will collaborate, innovate, and have the chance to win exciting prizes (exact details to be determined after sponsorship). We aim to foster creativity, teamwork, and learning in a diverse and inclusive environment. Join us at Neelgai Janakpur Office for a weekend full of exciting opportunities to explore new technologies and make a meaningful impact!',
    LOGO: hackathonLogo // Ensure hackathonLogo is properly imported/defined
};
export const frequentlyAskedQuestions = [
    [
        [
            {
                label: 'What is a hackathon?',
                content:
                    'A hackathon is a 48-hour event where "hackers" from various fields collaborate to create functioning software or hardware solutions by the end of the event.'
            },
            {
                label: 'Who can attend?',
                content:
                    'Janakpur Hackathon is open to individuals aged 16-28, including high school and university students, as well as recent graduates. Teams are encouraged to have at least one female participant.'
            },
            {
                label: 'I am a first-time hacker, what should I do?',
                content:
                    'No worries! We welcome first-time hackers and will have workshops and mentors to help you get started with hacking and building projects.'
            },
            {
                label: 'Will food, accommodation, and travel allowance be provided?',
                content:
                    'We provide free food and accomodation for 23rd Nov (limited, on a first come first serve basis) and travel allowance of NRs 500.'
            },
            {
                label: 'Will the hackathon have a theme?',
                content: 'Yes, this hackathon will not be open-themed. A specific theme will be provided at the event site to guide your project ideas.'
            }
        ],
        [
            {
                label: 'How do I register for the hackathon?',
                content:
                    'To register, fill out the form on the website. The event is free for everyone.'
            },
            {
                label: 'How does team formation work?',
                content:
                    "You can form a team of 3-4 members. If you don't have a team, don't worry—we'll provide opportunities to meet other participants and form teams during the event."
            },
            {
                label: 'I have more questions, who should I ask?',
                content:
                    'Feel free to reach out to us at (hackathon@neelgai.com) and we’ll be happy to assist you.'
            },
            {
                label: 'What should I bring?',
                content:
                    'Participants are required to bring their own laptops and any other project-related equipment they might need. Neelgai will provide a place to work and WiFi access for the duration of the event.'
            }
        ]
    ]
];


export const Prizeinfo = [
    [
        // Array 1
        {
            icon: <i className="first fas fa-4x fa-trophy"></i>,
            type: 'Best Overall',
            content:
                'The Best Overall prize will be awarded to the project that stands out across all categories in terms of innovation, functionality, and impact. The exact prize details are still to be determined after securing sponsorship.'
        },
        {
            icon: <i className="second fas fa-4x fa-lightbulb"></i>,
            type: 'Most Innovative',
            content:
                'The Most Innovative prize will be awarded to the project that shows the most creative and novel approach to solving problems. The exact prize details are still to be determined after securing sponsorship.'
        },
        {
            icon: <i className="third fas fa-4x fa-laptop-code"></i>,
            type: 'Best Use of Technology',
            content:
                'This prize will be given to the team that demonstrates the best use of technology in their solution. The exact prize details are still to be determined after securing sponsorship.'
        }
    ],
];

export const sponsorLogos = [
    [{ src:  c4NLogo}, {src:  leapFrogLogo}, {src:logoPassport}]
];

export const mediaPartners = [
    [{src: avniMedia}]
]
export const hostingPartners = [
    [{src: Protozoa}]
]
export const supportedBy = [
    [{src: maaMadheshLogo}]
]

export const FOOTER = {
    VOLUNTEERING_FORM: {
        required: false,
        src: ''
    },
    JOIN_TEAM: {
        required: false,
        src: ''
    },
    Privacy_policy: {
        required: false,
        src: ''
    },
    Terms_of_use: {
        required: false,
        src: ''
    }
};

/** Instructions
 * Insert only 3 or less then 3 members per array in `TeamInfo`, you can
 * create as many arrays you want all team members will be rendered
 * in a group of 3.
 */

export const TeamInfo = [
    [
        //Array 1
        {
            name: 'Nandani Sah',
            role: 'Event Coordinator',
            github: 'https://github.com/Nandani01',
            linkedin: 'http://www.linkedin.com/in/nandanisah',
            img: nandaniShah
        },
        {
            name: 'Sat Narayan Sah',
            role: 'Marketing/PR Lead',
            github: 'https://github.com/SatNarayanSah',
            linkedin: 'https://www.linkedin.com/in/sat-narayan-sah-067286227/',
            img: satNarayanSah
        },
        {
            name: 'Mikey Sharma',
            role: 'Tech Support',
            github: 'https://github.com/MikeySharma',
            linkedin: 'https://www.linkedin.com/in/mikey-sharma',
            img: mikeySharma
        },
    ],
    [
        //Array 2

        {
            name: 'Pankaj Shah',
            role: 'Logistics Manager',
            github: 'https://github.com/pankajshah749',
            linkedin: 'https://www.linkedin.com/in/pankaj-kr-sah-a52a64286/',
            img: pankajShah
        },
        {
            name: 'Milan Sah',
            role: 'Sponsorship Coordinator',
            github: 'https://github.com/milansaa25',
            linkedin: 'https://www.linkedin.com/in/milansaa25/',
            img: milanSah
        },{
            name: 'Parul Sah',
            role: 'Volunteer Coordinator',
            github: '',
            linkedin: '',
            img: parulSah
        }
    ]
];

export const JudgesInfo = [
    [
        //Array 1
        {
            name: 'Deepak Shah',
            role: 'Co-Founder',
            github: 'https://github.com/kalapathar',
            linkedin: 'https://www.linkedin.com/in/kalapathar/',
            img: deepakShah
        },
        {
            name: 'Suraj Kumar Sah',
            role: 'DevOps / MLOps Engineer',
            github: '',
            linkedin: 'https://www.linkedin.com/in/surajkumarsah/',
            img: surajSah
        },

    ],
];
export const mentorsInfo = [
    [
        //Array 1
        {
            name: 'Yuvraj Singh',
            role: 'React Instructor',
            github: 'https://github.com/yuvrajsingh176',
            linkedin: 'https://www.linkedin.com/in/yuvraj-singh-a46164206/',
            img: yuvraj
        },
        {
            name: 'Roshan Jha',
            role: 'Bootcamp Coordinator',
            github: 'https://github.com/Roshanzha',
            linkedin: 'https://www.linkedin.com/in/roshanzha/',
            img: roshanJha
        },
        {
            name: 'Sujeet Kumar Jha',
            role: 'Data Science Instructor',
            github: '',
            linkedin: '',
            img: sujitSir
        },

    ], [
        {
            name: 'Bibek Thapa',
            role: 'AI/ML Instructor',
            github: 'https://github.com/HordesOfGhost',
            linkedin: 'https://www.linkedin.com/in/bibek-thapa-sb1129/',
            img: bibekSir
        },
        {
            name: 'Nawaj Sarif',
            role: 'Python Django Instructor',
            github: 'https://github.com/Nawaj2417',
            linkedin: 'https://www.linkedin.com/in/nawaj2417/',
            img: nawajSarif
        }, {
            name: 'Suruchi Shah',
            role: 'Marketing Coordinator',
            github: 'https://github.com/Suruchishah',
            linkedin: 'https://www.linkedin.com/in/suruchi-shah-106510231/',
            img: suruchiShah
        }
    ]
];