
import Images from "./Images";
export const blogs = [
    {
      id: 1,
      title:
        "How we prevent conflicts in authoritative DNS configuration using formal verification",
      date: "2024-11-03",
      slug: "blog-tech",
      blog_image: Images.blog_test,
      tag: [
        {
          id: 1,
          name: "Post-Quantam",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
        {
          id: 2,
          name: "Developer Platform",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
        {
          id: 3,
          name: "Workflows",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
      ],
      details:
        "A regular reader of the blog will know that this is not the first time we’ve taken measure of post-quantum signatures. In 2021 we took a first hard look, and reported on the performance impact we expect from large-scale measurements.  Since then, dozens of new post-quantum algorithms have been proposed. Many of them have been submitted to this new NIST competition. We discussed some of the more promising ones in our early 2024 blog post.",

      posted_by: [
        {
          id: 1,
          name: "Bas Westerbaan",
          image: Images.Sumit,
        },
        {
          id: 21,
          name: "Bas Westerbaan",
          image: Images.bidit,
        },
      ],
    },

    {
      id: 2,
      image: Images.blog_test,
      slug: "blog-tech2",
    },
    {
      id: 3,
      title:
        "Elephants in tunnels: how Hyperdrive connects to databases inside your VPC networks",
      date: "2024-11-03",
      slug: "blog-tech3",
      blog_image: Images.blog_test,
      tag: [
        {
          id: 1,
          name: "Agile Developer",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
        {
          id: 2,
          name: "Post-Quantam",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
        {
          id: 3,
          name: "DDoS Reports",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
      ],
      details:
        "A regular reader of the blog will know that this is not the first time we’ve taken measure of post-quantum signatures. In 2021 we took a first hard look, and reported on the performance impact we expect from large-scale measurements.  Since then, dozens of new post-quantum algorithms have been proposed. Many of them have been submitted to this new NIST competition. We discussed some of the more promising ones in our early 2024 blog post.",

      posted_by: [
        {
          id: 1,
          name: "Bas Westerbaan",
          image: Images.Deepak,
        },
        {
          id: 21,
          name: "Bas Westerbaan",
          image: Images.apoorva_netflix,
        },
      ],
    },
    {
      id: 4,
      title:
        "Billions and billions (of logs): scaling AI Gateway with the Cloudflare Developer Platform",
      date: "2024-11-03",
      slug: "blog-tech4",
      tag: [
        {
          id: 1,
          name: "Cloudflare One",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
        {
          id: 2,
          name: "Acquisitions",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
        {
          id: 3,
          name: "SSH",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
      ],
      details:
        "A regular reader of the blog will know that this is not the first time we’ve taken measure of post-quantum signatures. In 2021 we took a first hard look, and reported on the performance impact we expect from large-scale measurements.  Since then, dozens of new post-quantum algorithms have been proposed. Many of them have been submitted to this new NIST competition. We discussed some of the more promising ones in our early 2024 blog post.",

      posted_by: [
        {
          id: 1,
          name: "Bas Westerbaan",
          image: Images.Arbind,
        },
        {
          id: 21,
          name: "Bas Westerbaan",
          image: Images.bidit,
        },
      ],
    },
    {
      id: 5,
      title:
        "Durable Objects aren't just durable, they're fast: a 10x speedup for Cloudflare Queues",
      date: "2024-11-03",
      slug: "blog-tech5",
      tag: [
        {
          id: 1,
          name: "Infrastructure",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
        {
          id: 2,
          name: "Firmware",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
        {
          id: 3,
          name: "Cloudflare Pages",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
      ],
      details:
        "A regular reader of the blog will know that this is not the first time we’ve taken measure of post-quantum signatures. In 2021 we took a first hard look, and reported on the performance impact we expect from large-scale measurements.  Since then, dozens of new post-quantum algorithms have been proposed. Many of them have been submitted to this new NIST competition. We discussed some of the more promising ones in our early 2024 blog post.",

      posted_by: [
        {
          id: 1,
          name: "Bas Westerbaan",
          image: Images.bishesh,
        },
        {
          id: 21,
          name: "Bas Westerbaan",
          image: Images.bidit,
        },
      ],
    },
    {
      id: 6,
      title:
        "Workers Builds: integrated CI/CD built on the Workers platform",
      date: "2024-11-03",
      slug: "blog-tech6",
      tag: [
        {
          id: 1,
          name: "AMD",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
        {
          id: 2,
          name: "Cloudflare Network",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
        {
          id: 3,
          name: "Post-Quantam",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
      ],
      details:
        "A regular reader of the blog will know that this is not the first time we’ve taken measure of post-quantum signatures. In 2021 we took a first hard look, and reported on the performance impact we expect from large-scale measurements.  Since then, dozens of new post-quantum algorithms have been proposed. Many of them have been submitted to this new NIST competition. We discussed some of the more promising ones in our early 2024 blog post.",

      posted_by: [
        {
          id: 1,
          name: "Bas Westerbaan",
          image: Images.anish_google,
        },
        {
          id: 21,
          name: "Bas Westerbaan",
          image: Images.apoorva_netflix,
        },
      ],
    },
    {
      id: 7,
      title:
        "Exploring Internet traffic shifts and cyber attacks during the 2024 US election",
      date: "2024-11-03",
      slug: "blog-tech7",
      tag: [
        {
          id: 1,
          name: "Post-Quantam",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
        {
          id: 2,
          name: "Partners",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
        {
          id: 3,
          name: "Firmware",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
      ],
      details:
        "A regular reader of the blog will know that this is not the first time we’ve taken measure of post-quantum signatures. In 2021 we took a first hard look, and reported on the performance impact we expect from large-scale measurements.  Since then, dozens of new post-quantum algorithms have been proposed. Many of them have been submitted to this new NIST competition. We discussed some of the more promising ones in our early 2024 blog post.",

      posted_by: [
        {
          id: 1,
          name: "Bas Westerbaan",
          image: Images.labbi,
        },
        ,
        {
          id: 3,
          name: "Bas Westerbaan",
          image: Images.suman_oracle,
        },
        {
          id: 4,
          name: "Bas Westerbaan",
          image: Images.shauharda_microsoft,
        },
        {
          id: 4,
          name: "Bas Westerbaan",
          image: Images.suweb,
        },
      ],
    },
    {
      id: 8,
      title:
        "A look at the latest post-quantum signature standardization candidates",
      date: "2024-11-03",
      slug: "blog-tech8",
      tag: [
        {
          id: 1,
          name: "Acquisitions",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
        {
          id: 2,
          name: "Post-Quantam",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
        {
          id: 3,
          name: "Firmware",
          details:
            "NIST has standardized four post-quantum signature schemes so far, and they’re not done yet: there are fourteen new candidates in the running for standardization.",
          images: Images.Neelgai_resource,
        },
      ],
      details:
        "A regular reader of the blog will know that this is not the first time we’ve taken measure of post-quantum signatures. In 2021 we took a first hard look, and reported on the performance impact we expect from large-scale measurements.  Since then, dozens of new post-quantum algorithms have been proposed. Many of them have been submitted to this new NIST competition. We discussed some of the more promising ones in our early 2024 blog post.",

      posted_by: [
        {
          id: 1,
          name: "Bas Westerbaan",
          image: Images.bidit,
        },
        {
          id: 21,
          name: "Bas Westerbaan",
          image: Images.Sumit,
        },
      ],
    },
  ];
  